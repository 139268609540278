<template>
  <div class="form">
    <b-form @submit.prevent="onSubmit(mode)">
      <p v-if="mode === 'profile'" class="form__title">
        Profil utilisateur
      </p>
      <p v-else class="form__title">
        Fiche utilisateur
      </p>
      <b-container fluid>
        <b-row class="form__row">
          <b-col :md="4">
            <b-form-group id="input-group-1" label="Nom" label-for="input-1">
              <b-form-input id="input-1" ref="form.nom" v-model="form.nom" placeholder="Entrez votre nom" required
                :disabled="mode === 'profile'" />
            </b-form-group>
            <b-form-group id="input-group-2" label="Prenom" label-for="input-2">
              <b-form-input id="input-2" ref="form.prenom" v-model="form.prenom" placeholder="Entrez votre prenom"
                required :disabled="mode === 'profile'" />
            </b-form-group>
            <b-form-group id="input-group-identifiant" label="identifiant" label-for="input-identifiant">
              <b-form-input id="input-identifiant" ref="form.identifiant" v-model="form.identifiant" required
                :disabled="mode === 'profile'" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="form__row">
          <b-col :md="4">
            <b-form-group id="input-group-4" label="Departement" label-for="input-4">
              <b-form-input id="input-4" ref="form.departement" v-model="form.departement"
                placeholder="Entrez votre departement" required :disabled="mode === 'profile'" />
            </b-form-group>
            <b-form-group id="input-group-5" label="role" label-for="input-5">
              <b-form-select v-if="mode !== 'ajouter'" v-model="form.roles[0]" :options="roleList"
                :disabled="mode === 'profile'" />
              <b-form-select v-else v-model="roles" :options="roleList" />
            </b-form-group>
            <b-form-group id="input-group-5" label="Enregistrement des appels" label-for="input-5">
              <b-form-select v-if="mode === 'modifier'" v-model="form.profile.callRecording" :options="callRecording" />
              <b-form-select v-else v-model="form.callRecording" :options="callRecording" :disabled="mode === 'profile'" />
            </b-form-group>
            <b-form-group id="input-group-6" label="Telephone" label-for="input-6">
              <b-form-input id="input-6" ref="form.telephone" v-model="form.telephone" type="number"
                placeholder="Entrez votre telephone" required :disabled="mode === 'profile'" />
            </b-form-group>
            <b-form-group id="input-group-7" label="Email address" label-for="input-7">
              <b-form-input id="input-7" ref="form.email" v-model="form.email" type="email" placeholder="Enter email"
                required :disabled="mode === 'profile'" />
            </b-form-group>

            <div v-if="showPasswordFields || mode === 'ajouter'">
              <b-form-group v-if="mode === 'profile'" id="input-group-8" label="Mot de passe actuel" label-for="input-8">
                <b-form-input id="input-8" ref="form.currentPassword" v-model="form.currentPassword" type="password"
                  placeholder="Entrez votre mot de passe actuelle" required />
              </b-form-group>
              <div v-if="mode === 'ajouter' || mode === 'profile'">
                <b-form-group id="input-group-9" :label="mode === 'profile' ? 'Nouveau mot de passe' : 'Mot de passe'"
                  label-for="input-9">
                  <b-form-input id="input-9" ref="form.password" v-model="form.password" type="password"
                    placeholder="Entrez votre mot de passe" required />
                </b-form-group>
                <b-form-group id="input-group-10" label="Confirmez votre mot de passe" label-for="input-10">
                  <b-form-input id="input-10" ref="form.confirmationPassword" v-model="form.confirmationPassword"
                    type="password" placeholder="Confirmez votre mot de passe" required />
                </b-form-group>
              </div>
            </div>

          </b-col>
        </b-row>

        <b-row class="form__row">
          <b-col :md="4" class="d-flex">
            <b-form-group id="input-group-8" label="Status" label-for="input-8">
              <b-form-checkbox id="status" ref="form.status" v-model="form.status" name="check-button" switch
                :disabled="mode === 'profile'" />
            </b-form-group>

            <b-form-group v-if="mode === 'modifier' && form.roles[0] === 'LeRefuge.Ecoutant'" id="input-group-9"
              label="Backup" label-for="input-9">
              <b-form-checkbox id="backup" ref="form.isBackup" v-model="form.profile.isBackup" name="check-button"
                switch :disabled="mode === 'profile'" />
            </b-form-group>

            <b-form-group v-show="roles === 'LeRefuge.Ecoutant'" id="input-group-9" label="Backup" label-for="input-9">
              <b-form-checkbox v-model="form.isBackup" name="check-button" switch />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="form__row m-2">
          <b-button v-if="mode !== 'modifier' && mode !== 'profile'" type="submit" variant="primary">Ajouter
          </b-button>
          <b-button v-if="mode === 'modifier' || showPasswordFields" type="submit" variant="primary">
            Modifier</b-button>
          <b-button class="cancel" v-if="mode === 'profile' && showPasswordFields" @click="showPasswordFields = false"
            variant="secondary ">Annuler
          </b-button>
          <b-button v-if="mode === 'profile' && !showPasswordFields" @click="showPasswordFields = true; scrollToBottom()"
            variant="primary">Modifier mon mot de passe
          </b-button>
        </b-row>
      </b-container>
    </b-form>
  </div>
</template>

<script>
import {
  BContainer,
  BCol,
  BRow,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BFormSelect,
} from 'bootstrap-vue'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BContainer,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    BFormSelect,
  },
  props: {
    userData: {
      default: () => ({}),
      type: Object,
      required: false,
    },
    email: {
      default: '',
      type: String,
      required: false,
    },
    phone: {
      default: '',
      type: String,
      required: false,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  methods: {
    scrollToBottom() {
      this.$nextTick(() => {
        if (document.body.scrollHeight > window.innerHeight) {
          setTimeout(() => {
            window.scrollTo({
            top: document.body.scrollHeight,
            left: 0,
            behavior: 'smooth',
          })
          }, 250)
        }
      })
    },
  },
  setup(userData) {
    const form = {
      ...userData.userData,
    }
    const roles = ref('')
    const showPasswordFields = ref(false)
    const currentUserId = userData.userData.id
    const mail = userData.userData.email
    const phoneNumber = userData.userData.phone
    const toast = useToast()

    const roleList = [
      { value: 'LeRefuge.Administrateur', text: 'Administrateur' },
      { value: 'LeRefuge.Coordinateur', text: 'Coordinateur' },
      { value: 'LeRefuge.Ecoutant', text: 'Ecoutant' },
    ]

    const callRecording = [
      { value: true, text: 'Oui' },
      { value: false, text: 'Non' },
    ]

    const displaySuccessToast = (title, icon, mode) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          position: 'bottom-right',
          icon,
          variant: mode,
        },
      })
    }

    function onSubmit(mode) {
      const profile = {
        department: form.departement,
        isActive: form.status,
        isBackup: form.isBackup,
        callRecording: form.callRecording,
      }

      const userObj = {
        firstName: form.prenom,
        lastName: form.nom.toUpperCase(),
        profile,
        roles: [roles.value],
        email: form.email,
        emailConfirmed: false,
        phoneNumberConfirmed: false,
        phoneNumber: form.telephone,
        userName: form.identifiant,
        ...(mode === 'ajouter' && {
          password: form.password,
          confirmationPassword: form.confirmationPassword,
        }),
      }

      if (mode === 'ajouter') {
        store.dispatch('auth/register', userObj)
          .then(() => {
            displaySuccessToast('Utilisateur ajouté', 'CheckIcon', 'success')
            store.dispatch('users/fetchUsers')
            this.$emit('submit', true)
          })
          .catch((error => {
            displaySuccessToast(error.toString(), 'XCircleIcon', 'danger')
          }))
      } else if (mode === 'modifier') {
        const NewUserObj = {
          ...userObj,
          roles: form.roles,
          profile: {
            department: form.departement,
            isActive: form.status,
            isBackup: form.profile.isBackup,
            callRecording: form.profile.callRecording,
          },
          id: form.id,
        }

        if (NewUserObj.roles[0] !== 'LeRefuge.Ecoutant') {
          NewUserObj.profile.isBackup = false
        }
        if (mail === form.email) {
          NewUserObj.emailConfirmed = true
        }
        if (phoneNumber === form.telephone) {
          NewUserObj.phoneNumberConfirmed = true
        }

        store.dispatch('users/updateUser', NewUserObj)
          .then(() => {
            displaySuccessToast('Utilisateur modifié', 'CheckIcon', 'success')
            this.$emit('submit', true)
          })
          .catch((error => {
            displaySuccessToast(error.toString(), 'XCircleIcon', 'danger')
          }))
      } else if (mode === 'profile') {
          if (form.currentPassword === form.password) {
            displaySuccessToast('Le nouveau mot de passe doit être différent du mot de passe actuel', 'XCircleIcon', 'danger')
            return
          }
          if (form.password !== form.confirmationPassword) {
            displaySuccessToast('Les mots de passes que vous avez saisis ne correspondent pas', 'XCircleIcon', 'danger')
            return
          }

          const resetPasswordBody = {
            currentPassword: form.currentPassword,
            newPassword: form.password,
            confirmNewPassword: form.confirmationPassword,
            userId: currentUserId,
          }

          store.dispatch('users/resetPassword', resetPasswordBody)
            .then(() => {
              displaySuccessToast('Mot de passe mis à jour', 'CheckIcon', 'success')

              setTimeout(() => {
                displaySuccessToast('Vous allez être redirigé', 'AlertTriangleIcon', 'warning')
              }, 500)

              setTimeout(() => {
                toast.clear()
                this.$router.push({ name: 'login' }).then(() => {
                  displaySuccessToast('Veuillez saisir votre nouveau mot de passe', 'InfoIcon', 'info')
                }).catch(() => {})
              }, 3000)

              this.$emit('submit', true)
            })
            .catch((error => {
              displaySuccessToast(error.toString(), 'XCircleIcon', 'danger')
            }))
      }
    }

    return {
      mail,
      callRecording,
      phoneNumber,
      toast,
      roles,
      roleList,
      onSubmit,
      displaySuccessToast,
      showPasswordFields,
      form,
    }
  },
}
</script>

<style lang="scss" scoped>
.form {
  &__title {
    font-size: 24px;
    display: flex;
    justify-content: center;
    margin: 2rem 0;
    color: #16B5EA;
    font-weight: 700;
  }

  &__row {
    justify-content: center;
  }

  .cancel {
    margin-left: 20px;
  }
}
</style>
